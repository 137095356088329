import React, {
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import MorphologyContent from "./MorphologyContent/MorphologyContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faLanguage,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Info.module.css";

const Info = () => {
  const {
    currentWordInstances,
    selectedWord,
    selectedFragment,
    getVocabularyByFragmentId,
    userProfile,
    bookTitle,
    currentSentence,
    currentSentenceId,
    prevSentenceId,
    showTranslationSection,
    setShowTranslationSection,
    showGrammarSection,
    setShowGrammarSection,
    isTransitioning,
  } = useContext(SentenceContext);

  const [prevSentence, setPrevSentence] = useState(null);
  const [delayedSentence, setDelayedSentence] = useState(null);

  useEffect(() => {
    if (prevSentence !== currentSentence) {
      setTimeout(() => {
        setDelayedSentence(currentSentence);
      }, 1000);
      setPrevSentence(currentSentence);
    }
  }, [prevSentence, currentSentence]);

  const grammarContentRef = useRef(null);

  const selectedWordInstance = currentWordInstances.find(
    (wi) => wi._id === selectedWord
  );

  const audioRef = useRef(null);
  // initialize only once
  if (!audioRef.current) {
    audioRef.current = new Audio();
  }

  // memoized
  const onSentenceTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const bookSrc = bookTitle.toLowerCase().replace(/[ ]/g, "-");
    const sentenceSrc = `${currentSentence.part}-${currentSentence.chapter}-${currentSentence.section}-${currentSentence.position}`;
    audioRef.current.src = `/${bookSrc}/${sentenceSrc}.wav`;

    console.log(audioRef.current.src);

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [bookTitle, currentSentence]);

  // setting1: autoPlayWordTTS
  useEffect(() => {
    if (userProfile?.settings?.autoPlaySentenceTTS) {
      onSentenceTTSClick();
    }
  }, [
    currentSentenceId,
    onSentenceTTSClick,
    userProfile?.settings?.autoPlaySentenceTTS,
  ]);

  useEffect(() => {
    if (isTransitioning && grammarContentRef.current) {
      const scrollDuration = 1000;
      const startPosition = grammarContentRef.current.scrollTop;
      const startTime = performance.now();

      const scroll = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / scrollDuration, 1);

        // Easing function for smoother motion
        const easeOutCubic = 1 - Math.pow(1 - progress, 3);

        grammarContentRef.current.scrollTop =
          startPosition * (1 - easeOutCubic);

        if (progress < 1) {
          requestAnimationFrame(scroll);
        }
      };

      requestAnimationFrame(scroll);
    }
  }, [isTransitioning]);

  return (
    <div className={styles.gridInfo}>
      <div className={styles.gridInfo_content}>
        {/* left side */}
        <div className={styles.translationCol}>
          <div
            className={`${styles.header} ${
              showTranslationSection ? `` : `${styles.header__off}`
            }`}
          >
            <div
              className={styles.headerToggle__sentence}
              onClick={() => setShowTranslationSection(!showTranslationSection)}
            >
              <div style={{ position: "relative" }}>
                <div
                  className={`${
                    showTranslationSection
                      ? `${styles.languageOnBackground}`
                      : `${styles.languageOffBackground}`
                  }`}
                ></div>
                <div
                  className={`${
                    showTranslationSection
                      ? styles.sentenceButtonOn
                      : styles.sentenceButtonOff
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faLanguage}
                    title={
                      showTranslationSection
                        ? "Show Sentence Translation"
                        : "Hide Sentence Translation"
                    }
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.translationTitle}`}>sentence</div>
          </div>
          <div className={styles.translationContent}>
            <div
              className={`${styles.sentenceTranslation} 
              ${showTranslationSection ? styles.revealed : styles.hidden}
              `}
            >
              {delayedSentence?.translation?.split(" ")?.map((word, index) => (
                <div>
                  <div
                    key={index}
                    className={styles.fadeInWord}
                    style={{
                      transitionDelay: showTranslationSection
                        ? `${1 + index * 0.04}s`
                        : "0s",
                    }}
                  >
                    {word}{" "}
                  </div>
                </div>
              ))}
              <span className={styles.spacer}></span>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className={styles.grammarCol}>
          <div
            className={`${styles.header} ${
              showGrammarSection ? `` : `${styles.header__off}`
            }`}
          >
            <div
              className={styles.headerToggle__grammar}
              onClick={() => setShowGrammarSection(!showGrammarSection)}
            >
              <div style={{ position: "relative" }}>
                <div
                  className={`${
                    !showGrammarSection
                      ? `${styles.grammarOnBackground}`
                      : `${styles.grammarOffBackground}`
                  }`}
                ></div>
                <div
                  className={`${
                    !showGrammarSection
                      ? styles.sentenceButtonOn
                      : styles.sentenceButtonOff
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faBookOpen}
                    title={
                      !showGrammarSection
                        ? "Show Sentence Translation"
                        : "Hide Sentence Translation"
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.grammarTitle}>word</div>
          </div>
          <div className={styles.grammarContent} ref={grammarContentRef}>
            <MorphologyContent
              showGrammarSection={showGrammarSection}
              selectedWordInstance={selectedWordInstance}
              getVocabularyByFragmentId={getVocabularyByFragmentId}
              selectedFragment={selectedFragment}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
