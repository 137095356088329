import React from "react";
import FlipDigit from "../FlipDigit/FlipDigit";
import styles from "./FlipCounter.module.css";

const FlipCounter = ({ number, animate = "up", isRightVal }) => {
  // Convert number to string and pad with zeros
  const digits = isRightVal
    ? number.toString().padStart(2, "0").split("").map(Number)
    : number.toString().padStart(4, "0").split("").map(Number);

  // Determine leading zeros
  const isLeadingZeroArray = [];
  let encounteredNonZero = false;

  digits.forEach((digit, index) => {
    // Check if this is the last digit
    const isLastDigit = index === digits.length - 1;

    if (isLastDigit) {
      // Always set the last digit's leading zero status to false
      isLeadingZeroArray.push(false);
    } else if (encounteredNonZero) {
      isLeadingZeroArray.push(false);
    } else {
      if (digit === 0) {
        isLeadingZeroArray.push(true);
      } else {
        isLeadingZeroArray.push(false);
        encounteredNonZero = true;
      }
    }
  });

  return (
    <div className={styles.container}>
      {digits.map((digit, index) => (
        <FlipDigit
          key={index}
          digit={digit}
          animate={animate}
          isLeadingZero={isLeadingZeroArray[index]}
          number={number}
          isRightVal={isRightVal}
        />
      ))}
    </div>
  );
};

export default FlipCounter;
