// todo: no spanish
export const LANGUAGE_VALUE_DESCRIPTIONS = {
  // spanish:
  //   "The modern form of Spanish which begins from the late 15th century and is still in use today",
  spanish: null,
  latin:
    "Latin is an ancient language of the Roman Empire, used from around the 6th century BC until the fall of the Roman Empire in the 5th century AD",
};

export const POS_VALUE_DESCRIPTIONS = {
  noun: "Nouns are people, places or things",
  adjective: "Adjectives describe or modify a noun",
  pronoun: "Pronouns hold the place of a noun",
  adposition:
    "Adpositions show direction, location, time, manner, cause, purpose or possession",
  verb: "Verbs describe an action, occurence or state",
  coordinatingConjunction:
    "Coordinating conjunctions connect words, phrases or clauses of equal importance",
  subordinatingConjunction:
    "Subordinating conjunctions connect an independent clause to a dependent clause",
  adverb: "Adverbs describe or modify a verb",
  determiner: "Determiners introduce a noun",
  abbreviation: "Abbreviations are shortened forms of a word or phrase",
  interjection: "Interjections express a sudden emotion or reaction",
  particle: "Particles are small function words which do not change form",
  properNoun:
    "Proper nouns are specific names given to a person, place or thing",
  numeral: "Numerals denote numbers or quantities",
};

export const DETERMINER_TYPE_VALUE_DESCRIPTIONS = {
  definite:
    "Definite determiners specify a particular, identifiable noun known to the listener or reader",
  demonstrative:
    "Demonstrative determiners point to a specific noun and indicate its proximity to the speaker in space or time",
  indefinite:
    "Indefinite determiners refer to a non-specific or unspecified noun",
  possessive:
    "Possessive determiners indicate ownership or possession of the noun which they modify",
  quantifier:
    "Quantifiers expresses the quantity of the noun, e.g. how much or how many",
};

// todo: add reflexive, intensifier
export const PRONOUN_TYPE_VALUE_DESCRIPTIONS = {
  personal:
    "Personal pronouns directly reference specific people, animals, objects, or concepts that have been previously mentioned or are understood from context",
  impersonal:
    "Impersonal pronouns refer to undefined, general, or non-specific subjects, often used in expressions where the actor is not important or known",
  possessive:
    "Possessive pronouns indicate ownership or belonging, replacing possessed nouns to avoid repetition",
  demonstrative:
    "Demonstrative pronouns indicate which specific items are being referred to and their relative position in space or time",
  interrogative:
    "Interrogative pronouns form questions about people, things, or ideas, helping to request specific information",
  relative:
    "Relative pronouns connect dependent clauses to independent clauses while referring back to previously mentioned nouns",
  indefinite:
    "Indefinite pronouns refer to one or more unspecified persons or things, expressing concepts without precise identification",
  reciprocal:
    "Reciprocal pronouns express mutual actions or relationships between two or more subjects, indicating that each is doing the same thing to the other",
  quantifier:
    "Quantifier pronouns express amounts or quantities of something, indicating how much or how many of the nouns they replace",
};

export const PRONOUN_FUNCTION_VALUE_DESCRIPTIONS = {
  subject:
    "Subject pronouns function as performers of actions in sentences, typically appearing before verbs",
  directObject:
    "Direct object pronouns receive direct actions of verbs, typically appearing after verbs or at beginnings of clauses",
  indirectObject:
    "Indirect object pronouns indicate to whom or for whom actions are performed, often appearing between subjects and direct objects",
  reflexive:
    "Reflexive pronouns indicate when subjects and objects of actions are the same entities, showing actions reflect back on subjects",
};

export const VERB_TYPE_VALUE_DESCRIPTIONS = {
  ar: '-ar verbs have an infinitive form ending in "-ar", which represents one of three main conjugation patterns in the language',
  er: '-er verbs have an infinitive form ending in "-er", which represents one of three main conjugation patterns in the language',
  ir: '-ir verbs have an infinitive form ending in "-ir", which represents one of three main conjugation patterns in the language',
};

export const FORM_VALUE_DESCRIPTIONS = {
  infinitive:
    "Infinitives represent base forms of verbs before conjugation, typically expressing simple concepts of actions or states",
  finite:
    "Finite verbs show specific grammatical information about tense, person, number, and mood",
  pastParticiple:
    "Past participles create compound tenses with auxiliary verbs, typically expressing completed actions or passive states",
  presentParticiple:
    "Present participles express continuous or ongoing actions, often used in progressive tenses",
};

export const MOOD_VALUE_DESCRIPTIONS = {
  indicative:
    "The indicative mood expresses factual statements, direct questions, or objective conditions considered real or certain",
  subjunctive:
    "The subjunctive mood expresses doubt, wishes, emotions, possibilities, or subjective statements that are uncertain or contrary to fact",
  imperative:
    "The imperative mood expresses direct commands, requests, suggestions, or prohibitions directed at listeners",
};

export const TENSE_VALUE_DESCRIPTIONS = {
  present:
    "The present tense expresses current actions, habitual activities, general truths, or states of being existing in current moments",
  preterite:
    "The preterite tense expresses completed past actions with clear beginnings and ends, or actions viewed as single events",
  imperfect:
    "The imperfect tense describes ongoing, habitual, or repeated actions in the past, or provide background information",
  future:
    "The future tense expresses actions or states occurring at later times, or make predictions about future events",
  conditional:
    "The conditional tense expresses hypothetical situations, polite requests, or actions depending on certain conditions being met",
};

export const PERSON_VALUE_DESCRIPTIONS = {
  first:
    "The first person represents speakers in discourse, including both singular 'I' and plural 'we' forms",
  second:
    "The second person represents people being addressed directly in discourse, including both formal and informal forms",
  third:
    "The third person represents people, things, or concepts being discussed that are neither speakers nor addressees",
};

export const GENDER_VALUE_DESCRIPTIONS = {
  masculine:
    "Masculine words traditionally associate with male beings, also including many inanimate objects and abstract concepts by convention",
  feminine:
    "Feminine words traditionally associate with female beings, also including many inanimate objects and abstract concepts by convention",
  neuter:
    "Neuter words apply to words neither masculine nor feminine, or whose gender remains unknown or unspecified",
};

export const NUMBER_VALUE_DESCRIPTIONS = {
  singular: "Singular words indicate exactly one person, thing, or concept",
  plural: "Plural words indicate more than one person, thing, or concept",
};

export const CLITIC_FUNCTION_VALUE_DESCRIPTIONS = {
  directObject:
    "Direct object clitics function as recipients of main verb actions, appearing as unstressed forms attached to or near verbs",
  indirectObject:
    "Indirect object clitics function as secondary recipients of actions, indicating to whom or for whom actions are performed",
  reflexive:
    "Reflexive clitics show subjects performing actions on themselves, creating reflexive relationships",
};

export const CLITIC_PERSON_VALUE_DESCRIPTIONS = {
  first:
    "1st person clitics represent speakers when used in verbal constructions",
  second:
    "2nd person clitics represent addressees when used in verbal constructions",
  third:
    "3rd person clitics represent neither speakers nor addressees when used in verbal constructions",
};

export const CLITIC_GENDER_VALUE_DESCRIPTIONS = {
  masculine:
    "Masculine clitics belong to grammatical categories traditionally associated with male beings and certain inanimate objects by convention",
  feminine:
    "Feminine clitics belong to grammatical categories traditionally associated with female beings and certain inanimate objects by convention",
};

export const CLITIC_NUMBER_VALUE_DESCRIPTIONS = {
  singular:
    "Singular clitics indicate exactly one person, animal, thing, or concept",
  plural:
    "Plural clitics indicate more than one person, animal, thing, or concept",
};
