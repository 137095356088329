// src/utils/cefrLevels.js
export const CEFR_LEVELS = [
  { level: "A0.1", min: 0, max: 249 },
  { level: "A0.2", min: 250, max: 499 },
  { level: "A1.1", min: 500, max: 749 },
  { level: "A1.2", min: 750, max: 999 },
  { level: "A2.1", min: 1000, max: 1499 },
  { level: "A2.2", min: 1500, max: 1999 },
  { level: "B1.1", min: 2000, max: 2999 },
  { level: "B1.2", min: 3000, max: 3999 },
  { level: "B2.1", min: 4000, max: 5999 },
  { level: "B2.2", min: 6000, max: 7999 },
  { level: "C1.1", min: 8000, max: 11999 },
  { level: "C1.2", min: 12000, max: 15999 },
  { level: "C2", min: 16000, max: Infinity },
];

// test
// export const CEFR_LEVELS = [
//   { level: "A0.1", min: 0, max: 0.5 },
//   { level: "A0.2", min: 1, max: 1.5 },
//   { level: "A1.1", min: 2, max: 2.5 },
//   { level: "A1.2", min: 3, max: 3.5 },
//   { level: "A2.1", min: 4, max: 4.5 },
//   { level: "A2.2", min: 5, max: 5.5 },
//   { level: "B1.1", min: 6, max: 6.5 },
//   { level: "B1.2", min: 7, max: 7.5 },
//   { level: "B2.1", min: 8, max: 8.5 },
//   { level: "B2.2", min: 9, max: 9.5 },
//   { level: "C1.1", min: 10, max: 10.5 },
//   { level: "C1.2", min: 11, max: 11.5 },
//   { level: "C2", min: 12, max: Infinity },
// ];

// test 2
// export const CEFR_LEVELS = [
//   { level: "A0.1", min: 0, max: 1 },
//   { level: "A0.2", min: 2, max: 3 },
//   { level: "A1.1", min: 4, max: 5 },
//   { level: "A1.2", min: 6, max: 7 },
//   { level: "A2.1", min: 8, max: 9 },
//   { level: "A2.2", min: 10, max: 11 },
//   { level: "B1.1", min: 12, max: 13 },
//   { level: "B1.2", min: 14, max: 15 },
//   { level: "B2.1", min: 16, max: 17 },
//   { level: "B2.2", min: 18, max: 19 },
//   { level: "C1.1", min: 20, max: 21 },
//   { level: "C1.2", min: 22, max: 23 },
//   { level: "C2", min: 24, max: Infinity },
// ];
