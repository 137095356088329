import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/shared/LogoV2/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Nav.module.css";

const Nav = ({
  setShowingSSVSettings,
  setShowingSSVHelp,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
}) => {
  const navigate = useNavigate();

  const {
    userProfile,
    knownCount,
    bookTitle,
    currentSentence,
    totalSentences,
    currentSentenceIndex,
    onNextSentenceClick,
    onPrevSentenceClick,
  } = useContext(SentenceContext);

  const shouldFlash = !userProfile?.hasLoggedIn;

  // down arrow key mapping -> "p"
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "p") {
        onPrevSentenceClick();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onPrevSentenceClick]);

  // up arrow key mapping -> "n"
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "n") {
        onNextSentenceClick();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onNextSentenceClick]);

  const handleExitClick = async () => {
    setShowingSSVSettings(false);
    setShowingSSVHelp(false);
    navigate("/library");
  };

  return (
    <>
      <div
        className={styles.logo}
        onClick={handleExitClick}
        title="Exit to Library"
      >
        <Logo />
      </div>

      <div className={styles.locationContainer}>
        <FontAwesomeIcon
          className={
            currentSentenceIndex + 1 > 1
              ? `${styles.icon}`
              : `${styles.icon__disabled}`
          }
          icon={faChevronUp}
          onClick={onPrevSentenceClick}
          title='Go to Previous Sentence - "P" Key'
        />
        <div className={styles.locationText}>
          <h2 className={styles.bookTitle}>{bookTitle}</h2>
          <h3 className={styles.chapterTitle}>
            {currentSentence?.chapterTitle}
          </h3>

          <p className={styles.sentencePosition}>
            sentence {currentSentenceIndex + 1} / {totalSentences}
          </p>
        </div>
        <FontAwesomeIcon
          className={
            currentSentenceIndex + 1 < totalSentences
              ? `${styles.icon}`
              : `${styles.icon__disabled}`
          }
          icon={faChevronDown}
          onClick={onNextSentenceClick}
          title='Go to Next Sentence - "N" Key'
        />
      </div>

      <div className={styles.header2__columnContainer}>
        <div className={styles.header2__iconRow}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faGear}
            title="Settings"
            onClick={() => handleShowSSVSettingsClick()}
          />
          <FontAwesomeIcon
            className={`${styles.icon} ${shouldFlash ? "flash-animation" : ""}`}
            icon={faQuestion}
            title="Help"
            onClick={handleShowSSVHelpClick}
          />
        </div>
        <div className={styles.knownCountContainer}>
          <img
            className={styles.flag}
            src="/img/flags/Spanish.png"
            alt="Spanish flag"
          ></img>
          <p
            className={`nowrap ${styles.knownCount}`}
            title="Total Known Fragments"
          >
            {knownCount || "--"}
          </p>
        </div>
      </div>
    </>
  );
};

export default Nav;
