import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import ScheduledMaintenance from "./components/ScheduledMaintenance/ScheduledMaintenance";

import AppLayout from "./components/shared/AppLayout/AppLayout";
import MainLayout from "./components/shared/MainLayout/MainLayout";

import HomeV3 from "./components/Home/HomeV3/HomeV3";
// import HomeV2 from "./components/Home/HomeV2";
import RequireNoAuth from "./features/auth/RequireNoAuth";
import SignUp from "./features/auth/SignUp/SignUp";
import Login from "./features/auth/Login/Login";
import About from "./components/shared/MainLayout/Footer/About/About";
import Contact from "./components/shared/MainLayout/Footer/Contact/Contact";
import FAQ from "./components/shared/MainLayout/Footer/FAQ/FAQ";
import ReportIssue from "./components/shared/MainLayout/Footer/ReportIssue/ReportIssue";
import Donate from "./components/shared/MainLayout/Footer/Donate/Donate";
import TermsOfService from "./components/shared/MainLayout/Footer/TermsOfService/TermsOfService";
import PrivacyPolicy from "./components/shared/MainLayout/Footer/PrivacyPolicy/PrivacyPolicy";

import VerificationExpired from "./features/auth/EmailVerification/VerificationExpired";
import VerificationFailure from "./features/auth/EmailVerification/VerificationFailure";
import VerificationSuccess from "./features/auth/EmailVerification/VerificationSuccess";
import ForgotPassword from "./features/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./features/auth/ResetPassword/ResetPassword";

import RequireAuth from "./features/auth/RequireAuth";
import Prefetch from "./features/auth/Prefetch";
import Library from "./features/library/Library";
import Profile from "./features/profile/Profile";
import Settings from "./features/settings/Settings";
import Sentence from "./features/sentence/Sentence";
import AdminLayout from "./features/admin/AdminLayout";
import AdminIndex from "./features/admin/AdminIndex";

import NotFound from "./components/NotFound/NotFound";

import { ROLES } from "./config/roles";
import { hydrateNotification } from "./components/shared/AppLayout/notification/notificationSlice";
import useTitle from "./hooks/useTitle";

function App() {
  const dispatch = useDispatch();

  // hydrate notification
  useEffect(() => {
    dispatch(hydrateNotification());
  }, [dispatch]);

  // todo: move to context
  const [showingSSVSettings, setShowingSSVSettings] = useState(false);
  const [showingSSVHelp, setShowingSSVHelp] = useState(false);
  const [showingTourPopup, setShowingTourPopup] = useState(false);
  const [tourActive, setTourActive] = useState(false);
  const [currentTourStep, setCurrentTourStep] = useState(0);

  const handleShowSSVSettingsClick = () => {
    if (showingSSVHelp) setShowingSSVHelp(false);
    setShowingSSVSettings(!showingSSVSettings);
  };

  const handleShowSSVHelpClick = () => {
    if (showingSSVSettings) setShowingSSVSettings(false);
    setShowingSSVHelp(!showingSSVHelp);
  };
  // end move to context

  // todo: store title in localStorage? so not wiped on refresh
  useTitle("Iter Lexici");

  const scheduledMaintenance = false;

  return scheduledMaintenance ? (
    <Routes>
      <Route path="*" element={<ScheduledMaintenance />} />
    </Routes>
  ) : (
    <Routes>
      <Route
        path="/"
        element={
          <AppLayout
            showingSSVSettings={showingSSVSettings}
            showingSSVHelp={showingSSVHelp}
            setShowingSSVSettings={setShowingSSVSettings}
            setShowingSSVHelp={setShowingSSVHelp}
            showingTourPopup={showingTourPopup}
            setShowingTourPopup={setShowingTourPopup}
            setTourActive={setTourActive}
            currentTourStep={currentTourStep}
            setCurrentTourStep={setCurrentTourStep}
          />
        }
      >
        {/* public routes */}
        <Route element={<MainLayout />}>
          <Route index element={<HomeV3 />} />
          {/* <Route index element={<HomeV2 />} /> */}
          <Route element={<RequireNoAuth />}>
            <Route path={"sign-up"} element={<SignUp />} />
            <Route path={"login"} element={<Login />} />
          </Route>
          <Route path={"about"} element={<About />} />
          <Route path={"contact"} element={<Contact />} />
          <Route path={"faq"} element={<FAQ />} />
          <Route path={"report-issue"} element={<ReportIssue />} />
          <Route path={"donate"} element={<Donate />} />
          <Route path={"terms-of-service"} element={<TermsOfService />} />
          <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
        </Route>

        {/* email verification & forgot password routes */}
        <Route
          path={"verification-expired"}
          element={<VerificationExpired />}
        />
        <Route
          path={"verification-failure"}
          element={<VerificationFailure />}
        />
        <Route
          path={"verification-success"}
          element={<VerificationSuccess />}
        />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        {/* protected routes */}
        <Route
          element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
        >
          <Route element={<Prefetch />}>
            <Route element={<MainLayout />}>
              <Route
                path="library"
                element={
                  <Library
                    setShowingTourPopup={setShowingTourPopup}
                    setTourActive={setTourActive}
                    setShowingSSVSettings={setShowingSSVSettings}
                    setShowingSSVHelp={setShowingSSVHelp}
                  />
                }
              />
              <Route path="profile" element={<Profile />} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route
              path="sv/:bookId/:chapter"
              element={
                <Sentence
                  showingSSVSettings={showingSSVSettings}
                  showingSSVHelp={showingSSVHelp}
                  setShowingSSVSettings={setShowingSSVSettings}
                  setShowingSSVHelp={setShowingSSVHelp}
                  handleShowSSVSettingsClick={handleShowSSVSettingsClick}
                  handleShowSSVHelpClick={handleShowSSVHelpClick}
                  showingTourPopup={showingTourPopup}
                  setShowingTourPopup={setShowingTourPopup}
                  tourActive={tourActive}
                  setTourActive={setTourActive}
                  currentTourStep={currentTourStep}
                  setCurrentTourStep={setCurrentTourStep}
                />
              }
            />

            {/* admin routes */}
            <Route element={<RequireAuth allowedRoles={[ROLES.admin]} />}>
              <Route path="admin" element={<AdminLayout />}>
                <Route index element={<AdminIndex />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* catch-all route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
