import React, { useContext, useState, useEffect, useRef } from "react";
import { SentenceContext } from "../SentenceContext/SentenceContext";
import { CEFR_LEVELS } from "../../../utils/cefrLevels";
import FlipCounter from "../../../components/FlipCounter/FlipCounter";
import styles from "./CEFR.module.css";
import Level from "../../../components/Level/Level";
import ReactConfetti from "react-confetti";

const CEFR = () => {
  const {
    knownCount,
    knownCountDown,
    knownCountUp,
    getVocabularyByFragmentId,
    selectedFragment,
  } = useContext(SentenceContext);

  // Replace the direct check with state
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [isLevelUp, setIsLevelUp] = useState(false);
  const [isCurrentLevelChanging, setIsCurrentLevelChanging] = useState(false);
  const [isNextLevelChanging, setIsNextLevelChanging] = useState(false);

  const shouldBlockEvents =
    isLevelUp || isCurrentLevelChanging || isNextLevelChanging;

  // Add useEffect for initial mount timeout
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialMount(false);
    }, 1000); // Adjust timeout duration as needed

    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures it only runs once on mount

  // const initialMount = knownCount === null;

  const vocabulary = getVocabularyByFragmentId(selectedFragment);

  const currentLevelIndex = CEFR_LEVELS.findIndex(
    (level) => knownCount >= level.min && knownCount <= level.max
  );
  const currentLevel =
    knownCount === 0 ? "A0.0" : CEFR_LEVELS[currentLevelIndex].level;
  const nextLevel =
    knownCount === 0
      ? "A0.1"
      : currentLevelIndex < CEFR_LEVELS.length - 1
      ? CEFR_LEVELS[currentLevelIndex + 1].level
      : "Max";

  const [prevNextLevel, setPrevNextLevel] = useState(nextLevel);
  const [prevCurrentLevel, setPrevCurrentLevel] = useState(currentLevel);
  const [prevKnownCount, setPrevKnownCount] = useState(knownCount);

  const { min: currentMin, max: currentMax } = CEFR_LEVELS[currentLevelIndex];
  const calculatedProgressPercentage = (
    ((knownCount - currentMin) / (currentMax - currentMin + 1)) *
    100
  ).toFixed(2);
  const [progressPercentage, setProgressPercentage] = useState(
    calculatedProgressPercentage
  );

  useEffect(() => {
    if (prevKnownCount !== knownCount) {
      setPrevKnownCount(knownCount);
    }
  }, [knownCount]);

  const celebrationTimerRef = useRef(null);
  const firstCelebration = knownCount === 1 && prevKnownCount === 0;

  useEffect(() => {
    if (
      (prevNextLevel !== nextLevel || firstCelebration) &&
      knownCount > prevKnownCount &&
      !isLevelUp &&
      prevKnownCount !== null
    ) {
      setIsLevelUp(true);
      setProgressPercentage((100).toFixed(2));
      celebrationTimerRef.current = setTimeout(() => {
        setPrevNextLevel(nextLevel);
        setPrevCurrentLevel(currentLevel);
        setProgressPercentage(calculatedProgressPercentage);
        setIsLevelUp(false);
      }, 10000);
    } else {
      // Cancel any existing celebration and immediately update states
      if (celebrationTimerRef.current) {
        clearTimeout(celebrationTimerRef.current);
        celebrationTimerRef.current = null;
      }
      setIsLevelUp(false);
      setPrevNextLevel(nextLevel);
      setPrevCurrentLevel(currentLevel);
      setProgressPercentage(calculatedProgressPercentage);
    }

    return () => {
      if (celebrationTimerRef.current) {
        clearTimeout(celebrationTimerRef.current);
      }
    };
  }, [nextLevel, currentLevel, knownCount]);

  const [leftVal, rightVal] = progressPercentage.split(".");

  const indexAnimateDirection = vocabulary?.state === "known" ? "up" : "down";

  const [isAnimating, setIsAnimating] = useState(false);

  const level = knownCount === 1 ? "A0" : prevNextLevel.substring(0, 2);
  const subLevel = knownCount === 1 ? "1" : prevNextLevel.slice(-1);
  const badgeUrl =
    level === "C2"
      ? `/badges/${level}.png`
      : `/badges/${level}-${subLevel}.png`;
  console.log(badgeUrl);

  useEffect(() => {
    if (shouldBlockEvents) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";

      // Add event blockers
      const blockEvents = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

      window.addEventListener("keydown", blockEvents, true);
      window.addEventListener("keyup", blockEvents, true);
      window.addEventListener("click", blockEvents, true);

      return () => {
        // Cleanup
        document.body.style.overflow = "";
        window.removeEventListener("keydown", blockEvents, true);
        window.removeEventListener("keyup", blockEvents, true);
        window.removeEventListener("click", blockEvents, true);
      };
    }
  }, [shouldBlockEvents]);

  return (
    <div className={`${styles.cefrContainer}`}>
      {/* Event blocking overlay */}
      {isLevelUp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            cursor: "not-allowed",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
      {isLevelUp && (
        <div>
          <ReactConfetti
            className={`${isLevelUp ? styles.confettiOut : ""} ${
              isLevelUp ? styles.confettiOut : ""
            }`}
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={2000}
            recycle={true}
            gravity={0.4}
            colors={[
              "#109868",
              "#d0b428",
              "#d09028",
              "#d06028",
              "#4d78cc",
              "#B267E6",
              "#e85aaa",
              "#e84545",
              "#bcbbbb",
              "#D16969",
            ]}
            style={{ position: "fixed", top: 0, left: 0, zIndex: 1000 }}
          />
          <img
            className={`${styles.badge} ${isLevelUp ? styles.badgeFly : ""}`}
            src={badgeUrl}
          ></img>
        </div>
      )}
      <div
        className={`${styles.currentLevel} ${
          knownCountDown ? styles.knownCountDown : ""
        }`}
        style={{ opacity: isInitialMount ? 0 : 1 }}
      >
        <Level
          level={prevCurrentLevel}
          onChangeStateUpdate={setIsCurrentLevelChanging}
        />
        {/* {prevCurrentLevel} */}
      </div>
      <div className={styles.cefrBarContainer}>
        <div className={styles.cefrBar}>
          <div
            className={`${styles.progressBar} ${
              isLevelUp
                ? styles.levelUpBar
                : knownCountUp
                ? styles.knownCountUpBar
                : ""
            } ${knownCountDown ? styles.knownCountDownBar : ""}`}
            style={{ width: `${progressPercentage}%` }}
          >
            <div
              className={`${styles.leftOfDecimal} ${
                isLevelUp
                  ? styles.levelUp
                  : knownCountUp
                  ? styles.knownCountUp
                  : ""
              } ${knownCountDown ? styles.knownCountDown : ""}`}
              style={{ opacity: isInitialMount ? 0 : 1 }}
            >
              <FlipCounter
                number={leftVal}
                animate={indexAnimateDirection}
                onAnimationComplete={() => setIsAnimating(false)}
              />
            </div>
            <div
              className={`${styles.decimal} ${
                isLevelUp
                  ? styles.levelUp
                  : knownCountUp
                  ? styles.knownCountUp
                  : ""
              } ${knownCountDown ? styles.knownCountDown : ""}`}
              style={{ opacity: isInitialMount ? 0 : 1 }}
            >
              .
            </div>
            <div
              className={`${styles.rightOfDecimal} ${
                isLevelUp
                  ? styles.levelUp
                  : knownCountUp
                  ? styles.knownCountUp
                  : ""
              } ${knownCountDown ? styles.knownCountDown : ""}`}
              style={{ opacity: isInitialMount ? 0 : 1 }}
            >
              <FlipCounter
                number={rightVal}
                animate={indexAnimateDirection}
                onAnimationComplete={() => setIsAnimating(false)}
                isRightVal={true}
              />
              %
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.nextLevel} ${
          isLevelUp ? styles.levelUp : knownCountUp ? styles.knownCountUp : ""
        }`}
        style={{ opacity: isInitialMount ? 0 : 1 }}
      >
        <Level
          level={prevNextLevel}
          onChangeStateUpdate={setIsNextLevelChanging}
        />
      </div>
    </div>
  );
};

export default CEFR;
