// Toolbar.js
import React, {
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faVolumeHigh,
  faVolumeXmark,
  faEye,
  faEyeSlash,
  faCheck,
  faRotateLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Toolbar.module.css";

const Toolbar = () => {
  const {
    currentWordInstances,
    selectedWordInstance,
    selectedFragment,
    getVocabularyByFragmentId,
    userProfile,
    translationVisible,
    handleSeeTranslationClick,
    onNextFragmentClick,
    onPrevFragmentClick,
    toggleMarkAsKnown,
    isSelectionCooldown,
    isToggleCooldown,
    knownCountUp,
    knownCountDown,
  } = useContext(SentenceContext);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("ended", handleEnded);

    // Cleanup event listeners on component unmount
    return () => {
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const vocabulary = getVocabularyByFragmentId(selectedFragment);

  const isKnown = vocabulary?.state === "known";

  const [delayedIsKnown, setDelayedIsKnown] = useState(isKnown);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedIsKnown(isKnown);
    }, 10);

    // Cleanup the timer if the component unmounts or if dependencies change
    return () => clearTimeout(timer);
  }, [isKnown]);

  const audioRef = useRef(null);
  const containerRef = useRef(null); // Ref for the container

  // Initialize audioRef only once
  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }
  }, []);

  const onFragmentTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const word = selectedWordInstance?.word?.text
      .toLowerCase()
      .replace(/[^a-záéíóúñ]/g, "");
    if (!word) return;

    audioRef.current.src = `/spanish-words/${word}.wav`;

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [selectedWordInstance]);

  useEffect(() => {
    if (userProfile?.settings?.autoPlayWordTTS) {
      onFragmentTTSClick();
    }
  }, [
    selectedWordInstance,
    onFragmentTTSClick,
    userProfile?.settings?.autoPlayWordTTS,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        event.stopPropagation();
        onPrevFragmentClick(currentWordInstances);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentWordInstances, onPrevFragmentClick]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === " " || event.key === "Spacebar") {
        event.preventDefault();
        event.stopPropagation();
        onFragmentTTSClick();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onFragmentTTSClick]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        event.stopPropagation();
        onNextFragmentClick(currentWordInstances);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentWordInstances, onNextFragmentClick]);

  // Keyboard handler for 'k' key with toggle cooldown
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key.toLowerCase() === "k") {
        event.preventDefault();
        event.stopPropagation();
        // Remove cooldown check for keyboard toggle
        if (selectedFragment) {
          toggleMarkAsKnown();
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [toggleMarkAsKnown, selectedFragment]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key.toLowerCase() === "t") {
        event.preventDefault();
        event.stopPropagation();
        handleSeeTranslationClick();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSeeTranslationClick]);

  const selectionText = () => {
    let selectionText = "--";

    if (selectedFragment) {
      const wordText = selectedWordInstance?.text;

      if (selectedWordInstance?.stem?._id === selectedFragment) {
        const stemText = selectedWordInstance?.stem?.text;
        selectionText = stemText;
        if (wordText.length > stemText.length) {
          selectionText = selectionText + "-";
        }
      } else {
        let endingText = "";

        if (selectedWordInstance?.ending1?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending1?.text;
        } else if (selectedWordInstance?.ending2?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending2?.text;
        } else if (selectedWordInstance?.ending3?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending3?.text;
        }

        selectionText = endingText;
        if (wordText && wordText.length > endingText.length) {
          selectionText = "-" + selectionText;
        }
      }
    }

    return selectionText;
  };

  let content = (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.positionToolbar}>
        <div className={`${styles.toolbarContainer}`}>
          <button
            onClick={() => onPrevFragmentClick(currentWordInstances)}
            type="button"
            className={styles.button}
            title="Previous - Left Arrow Key"
            disabled={isToggleCooldown} // Disable during toggle cooldown
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
          </button>
          <button
            onClick={onFragmentTTSClick}
            type="button"
            className={`${styles.button} ${
              !selectedFragment ? styles.buttonOff : ""
            } ${isPlaying ? styles.playing : ""}`}
            title={selectedFragment ? "Play Word TTS" : ""}
            disabled={isToggleCooldown} // Disable during toggle cooldown if necessary
          >
            <FontAwesomeIcon className={styles.icon} icon={faVolumeHigh} />
          </button>
          <button
            onClick={() => {
              // Always allow toggling the selected fragment
              if (selectedFragment) {
                toggleMarkAsKnown();
              }
            }}
            type="button"
            className={`${styles.button} ${
              !selectedFragment ? styles.buttonOff : ""
            }`}
            title={
              vocabulary?.state !== "known"
                ? 'Mark Fragment as Known - "K" Key'
                : 'Revert Fragment to Seen - "K" Key'
            }
            // Remove the disabled attribute to allow interaction with selected fragment
            // disabled={isToggleCooldown || isSelectionCooldown}
          >
            <FontAwesomeIcon
              className={`${styles.check} ${
                !selectedFragment
                  ? styles.checkOff
                  : !delayedIsKnown
                  ? styles.glint
                  : styles.checkUndo
              }`}
              icon={faCheck}
            />
          </button>
          <button
            onClick={handleSeeTranslationClick}
            type="button"
            className={`${styles.button} ${
              !selectedFragment ? styles.buttonOff : ""
            }`}
            title={
              translationVisible[selectedWordInstance?._id]
                ? 'Hide Word Translation - "T" Key'
                : 'Show Word Translation - "T" Key'
            }
            disabled={isToggleCooldown} // Disable during toggle cooldown if necessary
          >
            <FontAwesomeIcon
              className={`${styles.icon} ${
                translationVisible[selectedWordInstance?._id]
                  ? styles.fadeOut
                  : styles.fadeIn
              } ${styles.eyeIcon}`}
              icon={faEye}
            />
            <FontAwesomeIcon
              className={`${styles.icon} ${
                translationVisible[selectedWordInstance?._id]
                  ? styles.fadeIn
                  : styles.fadeOut
              } ${styles.eyeSlashIcon}`}
              icon={faEyeSlash}
            />
          </button>
          <button
            onClick={() => onNextFragmentClick(currentWordInstances)}
            type="button"
            className={styles.button}
            title="Next - Right Arrow Key"
            disabled={isToggleCooldown} // Disable during toggle cooldown
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Toolbar;
