// WordInstance.jsx
import React, { memo, useEffect, useRef, useState, useContext } from "react";
import { SentenceContext } from "../SentenceContext/SentenceContext";
import styles from "./Target.module.css";

const WordInstance = ({
  wordInstanceId,
  translation,
  leadingPunctuation,
  trailingPunctuation,
  isSelectedWord,
  slices,
  modernEquivalent,
  olLeftOffset,
  olRightOffset,
  indent,
  transitionStage,
  hasNoNewFragments,
  hasNoSeenFragments,
  hasNoKnownFragments,
}) => {
  const {
    translationVisible,
    handleFragmentSelection,
    isSelectionCooldown,
    isToggleCooldown,
    knownCountDown,
    knownCountUp,
  } = useContext(SentenceContext);

  const [alignmentClass, setAlignmentClass] = useState("");
  const tableRef = useRef(null);
  const [hasFadedIn, setHasFadedIn] = useState(false);

  useEffect(() => {
    if (tableRef.current) {
      const rect = tableRef.current.getBoundingClientRect();
      const threshold = 10;
      const touchesLeft = rect.left <= olLeftOffset + threshold;
      const touchesRight = rect.right >= olRightOffset - threshold;

      if (touchesLeft) {
        setAlignmentClass(styles.alignLeft);
      } else if (touchesRight) {
        setAlignmentClass(styles.alignRight);
      } else {
        setAlignmentClass("");
      }
    }
  }, [olLeftOffset, olRightOffset]);

  const baseDelay = 2;

  const getAnimationDelay = (state) => {
    let finalDelay;
    switch (state) {
      case "known":
        finalDelay =
          baseDelay -
          hasNoKnownFragments -
          hasNoSeenFragments -
          hasNoNewFragments;
        break;
      case "seen":
        finalDelay = baseDelay - 1 - hasNoSeenFragments - hasNoNewFragments;
        break;
      case "new":
        finalDelay = baseDelay - 2 - hasNoNewFragments;
        break;
      case "punctuation":
        finalDelay =
          baseDelay -
          hasNoKnownFragments -
          hasNoSeenFragments -
          hasNoNewFragments;
        break;
      default:
        finalDelay = 0;
    }

    // return `${finalDelay}s`;
    return `0s`;
  };

  const handleAnimationEnd = () => {
    setHasFadedIn(true);
  };

  return (
    <li
      key={wordInstanceId}
      className={indent ? `${styles.paragraphIndentation}` : ""}
    >
      <table ref={tableRef}>
        <tbody>
          {/* Translation row */}
          <tr
            className={`${styles.translation} ${
              translationVisible[wordInstanceId] ? styles.visible : ""
            }`}
          >
            {leadingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationDelay:
                    transitionStage === "fadeIn"
                      ? getAnimationDelay("punctuation")
                      : "0s",
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              ></td>
            )}
            <td>{translation}</td>
            {trailingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationDelay:
                    transitionStage === "fadeIn"
                      ? getAnimationDelay("punctuation")
                      : "0s",
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              ></td>
            )}
          </tr>

          {/* Word row */}
          <tr id={wordInstanceId} className={styles.wordInstance}>
            {leadingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationDelay:
                    transitionStage === "fadeIn"
                      ? getAnimationDelay("punctuation")
                      : "0s",
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              >
                {leadingPunctuation}
              </td>
            )}
            <td className={`${styles.wordInstanceText} ${alignmentClass}`}>
              {slices.map((slice, index) => {
                const delay =
                  transitionStage === "fadeIn" // && !hasFadedIn
                    ? getAnimationDelay(slice.vocabulary?.state)
                    : "0s";

                const isDisabled = isSelectionCooldown || isToggleCooldown;

                return (
                  <span
                    onClick={() => {
                      // Allow interaction with currently selected fragment
                      if (
                        slice.isSelectedFragment ||
                        (!isSelectionCooldown && !isToggleCooldown)
                      ) {
                        handleFragmentSelection(
                          wordInstanceId,
                          slice.fragmentId,
                          slice.vocabulary
                        );
                      }
                    }}
                    id={slice.fragmentId}
                    key={slice.fragmentId}
                    className={`${styles.fragment}
                      ${isSelectedWord ? `${styles.selectedWord}` : ``}
                      ${
                        slice.isSelectedFragment
                          ? `${styles.selectedFragment}`
                          : ``
                      }
                      ${slice.type}
                      ${slice.vocabulary?.state || "new"} ${
                      transitionStage === "fadeIn" ? styles.animate : ""
                    }
                    `}
                    style={{
                      animationDelay: delay,
                      animationFillMode: "forwards",
                      opacity: transitionStage === "fadeIn" ? 0 : 1,
                      cursor:
                        !slice.isSelectedFragment &&
                        (isSelectionCooldown || isToggleCooldown)
                          ? "not-allowed"
                          : "pointer",
                      pointerEvents:
                        !slice.isSelectedFragment &&
                        (isSelectionCooldown || isToggleCooldown)
                          ? "none"
                          : "auto",
                      "--fragment-content": `"${slice.text}"`,
                    }}
                    onAnimationEnd={handleAnimationEnd}
                  >
                    {slice.text}
                  </span>
                );
              })}
            </td>
            {trailingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationDelay:
                    transitionStage === "fadeIn"
                      ? getAnimationDelay("punctuation")
                      : "0s",
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              >
                {trailingPunctuation}
              </td>
            )}
          </tr>

          {/* Modern equivalent row */}
          {/* <tr className={styles.modernEquivalentRow}>
            <td>
              {modernEquivalent && (
                <span className={styles.wordInstanceModernEquivalent}>
                  {modernEquivalent}
                </span>
              )}
            </td>
          </tr> */}
        </tbody>
      </table>
    </li>
  );
};

const memoizedWordInstance = memo(WordInstance);

export default memoizedWordInstance;
