import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthContext } from "../auth/AuthContext";
import { clearNotification } from "../../components/shared/AppLayout/notification/notificationSlice";
import { useGetUserProfileQuery } from "../profile/profileApiSlice";
import { useGetAllBooksQuery } from "./booksApiSlice";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import ErrMsg from "../../components/shared/ErrMsg/ErrMsg";
import Lesson from "./Lesson/Lesson";
import useTitle from "../../hooks/useTitle";

import styles from "./Library.module.css";

const Library = ({
  setShowingTourPopup,
  setTourActive,
  setShowingSSVSettings,
  setShowingSSVHelp,
}) => {
  useTitle("Library");

  const { urlToken, setSub } = useContext(AuthContext);
  const { data: userProfile } = useGetUserProfileQuery("userProfile", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setSub(userProfile?.activeSubscription);
  }, [setSub, userProfile]);

  const shouldFlash = !userProfile?.hasLoggedIn;

  const dispatch = useDispatch();

  // clear notification if oauth
  if (urlToken) dispatch(clearNotification());

  useEffect(() => {
    setShowingTourPopup(false);
    setTourActive(false);
    setShowingSSVSettings(false);
    setShowingSSVHelp(false);
  }, [
    setShowingTourPopup,
    setTourActive,
    setShowingSSVSettings,
    setShowingSSVHelp,
  ]);

  const {
    data: books,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllBooksQuery("library", {});

  let content;

  if (isLoading)
    content = (
      <div className="grid-center heroHeight">
        <LoadMsg msg="loading" size="2x" />
      </div>
    );

  if (isError) {
    console.log(error?.data?.message);
    content = (
      <div className="grid-center heroHeight">
        <ErrMsg msgList={[error?.data?.message]} size="2x" />
      </div>
    );
  }

  if (isSuccess && books && userProfile) {
    const { ids } = books;

    const DON_QUIXOTE_bookId = ids[0];

    const libraryContent = [
      <Lesson
        key={`${DON_QUIXOTE_bookId}_1`}
        bookId={DON_QUIXOTE_bookId}
        chapter={1}
        coverSrc="/img/lesson-covers/don-quixote/chapter1.jpeg"
        progress={
          userProfile?.bookProgress?.[DON_QUIXOTE_bookId]?.["1"] || null
        }
        available={true}
        free={true}
        shouldFlash={shouldFlash}
      />,
      <Lesson
        key={`${DON_QUIXOTE_bookId}_2`}
        bookId={DON_QUIXOTE_bookId}
        chapter={2}
        coverSrc="/img/lesson-covers/don-quixote/chapter2.jpeg"
        progress={null}
        available={true}
      />,
      <Lesson
        key={`${DON_QUIXOTE_bookId}_3`}
        bookId={DON_QUIXOTE_bookId}
        chapter={3}
        coverSrc="/img/lesson-covers/don-quixote/chapter3.jpeg"
        progress={null}
        available={false}
      />,
      // TODO: add filters, recently read & subscription logic
      // TODO: https://developer.mozilla.org/en-US/docs/Web/Performance/Lazy_loading

      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={4}
        coverSrc="/img/lesson-covers/don-quixote/chapter4.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={5}
        coverSrc="/img/lesson-covers/don-quixote/chapter5.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={6}
        coverSrc="/img/lesson-covers/don-quixote/chapter6.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={7}
        coverSrc="/img/lesson-covers/don-quixote/chapter7.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={8}
        coverSrc="/img/lesson-covers/don-quixote/chapter8.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={9}
        coverSrc="/img/lesson-covers/don-quixote/chapter9.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={10}
        coverSrc="/img/lesson-covers/don-quixote/chapter10.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={11}
        coverSrc="/img/lesson-covers/don-quixote/chapter11.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={12}
        coverSrc="/img/lesson-covers/don-quixote/chapter12.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={13}
        coverSrc="/img/lesson-covers/don-quixote/chapter13.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={14}
        coverSrc="/img/lesson-covers/don-quixote/chapter14.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={15}
        coverSrc="/img/lesson-covers/don-quixote/chapter15.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={16}
        coverSrc="/img/lesson-covers/don-quixote/chapter16.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={17}
        coverSrc="/img/lesson-covers/don-quixote/chapter17.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={18}
        coverSrc="/img/lesson-covers/don-quixote/chapter18.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={19}
        coverSrc="/img/lesson-covers/don-quixote/chapter19.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={20}
        coverSrc="/img/lesson-covers/don-quixote/chapter20.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={21}
        coverSrc="/img/lesson-covers/don-quixote/chapter21.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={22}
        coverSrc="/img/lesson-covers/don-quixote/chapter22.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={23}
        coverSrc="/img/lesson-covers/don-quixote/chapter23.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={24}
        coverSrc="/img/lesson-covers/don-quixote/chapter24.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={25}
        coverSrc="/img/lesson-covers/don-quixote/chapter25.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={26}
        coverSrc="/img/lesson-covers/don-quixote/chapter26.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={27}
        coverSrc="/img/lesson-covers/don-quixote/chapter27.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={28}
        coverSrc="/img/lesson-covers/don-quixote/chapter28.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={29}
        coverSrc="/img/lesson-covers/don-quixote/chapter29.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={30}
        coverSrc="/img/lesson-covers/don-quixote/chapter30.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={31}
        coverSrc="/img/lesson-covers/don-quixote/chapter31.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={32}
        coverSrc="/img/lesson-covers/don-quixote/chapter32.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={33}
        coverSrc="/img/lesson-covers/don-quixote/chapter33.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={34}
        coverSrc="/img/lesson-covers/don-quixote/chapter34.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={35}
        coverSrc="/img/lesson-covers/don-quixote/chapter35.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={36}
        coverSrc="/img/lesson-covers/don-quixote/chapter36.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={37}
        coverSrc="/img/lesson-covers/don-quixote/chapter37.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={38}
        coverSrc="/img/lesson-covers/don-quixote/chapter38.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={39}
        coverSrc="/img/lesson-covers/don-quixote/chapter39.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={40}
        coverSrc="/img/lesson-covers/don-quixote/chapter40.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={41}
        coverSrc="/img/lesson-covers/don-quixote/chapter41.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={42}
        coverSrc="/img/lesson-covers/don-quixote/chapter42.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={43}
        coverSrc="/img/lesson-covers/don-quixote/chapter43.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={44}
        coverSrc="/img/lesson-covers/don-quixote/chapter44.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={45}
        coverSrc="/img/lesson-covers/don-quixote/chapter45.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={46}
        coverSrc="/img/lesson-covers/don-quixote/chapter46.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={47}
        coverSrc="/img/lesson-covers/don-quixote/chapter47.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={48}
        coverSrc="/img/lesson-covers/don-quixote/chapter48.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={49}
        coverSrc="/img/lesson-covers/don-quixote/chapter49.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={50}
        coverSrc="/img/lesson-covers/don-quixote/chapter50.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={51}
        coverSrc="/img/lesson-covers/don-quixote/chapter51.jpeg"
        progress={null}
        available={false}
      />,
      <Lesson
        bookId={DON_QUIXOTE_bookId}
        chapter={52}
        coverSrc="/img/lesson-covers/don-quixote/chapter52.jpeg"
        progress={null}
        available={false}
      />,
    ];

    content = (
      <div className={styles.container}>
        <h2 className={styles.h2}>Library</h2>
        <div className={styles.gridCenter}>
          <ul className={styles.list}>{libraryContent}</ul>
        </div>
      </div>
    );
  }

  return <main className="page-wrapper">{content}</main>;
};

export default Library;
