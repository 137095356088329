import React, { memo, useEffect, useRef, useState, useContext } from "react";
import WordInstance from "./WordInstance";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Target.module.css";

const Target = () => {
  const {
    targetSentenceRef,
    currentWordInstances,
    beginsParagraph,
    selectedWord,
    selectedFragment,
    getVocabularyByFragmentId,
  } = useContext(SentenceContext);

  const olRef = useRef(null);
  const [olLeftOffset, setOlLeftOffset] = useState(null);
  const [olRightOffset, setOlRightOffset] = useState(null);

  const updateOffsets = () => {
    if (olRef.current) {
      const rect = olRef.current.getBoundingClientRect();
      setOlLeftOffset(rect.left);
      setOlRightOffset(rect.right);
    }
  };

  useEffect(() => {
    updateOffsets();
    window.addEventListener("resize", updateOffsets);

    return () => {
      window.removeEventListener("resize", updateOffsets);
    };
  }, [currentWordInstances]);

  const content = (
    <ol
      id="target"
      ref={olRef}
      className={`fadeIn-animation ${styles.sentence}`}
    >
      {currentWordInstances.map((wordInstance, index) => {
        const {
          _id: wordInstanceId,
          text: wordInstanceText,
          word,
          translation,
          stem,
          ending1,
          ending2,
          ending3,
          leadingPunctuation,
          trailingPunctuation,
        } = wordInstance;

        let mutablewordInstanceText = wordInstanceText;
        const fragments = [stem, ending1, ending2, ending3].filter(
          (obj) => obj !== null
        );
        const slices = fragments.map((fragment, index) => {
          const textLength = fragment.text.length;
          const textSlice = mutablewordInstanceText.slice(0, textLength);
          mutablewordInstanceText = mutablewordInstanceText.slice(textLength);
          const vocabulary = getVocabularyByFragmentId(fragment._id);

          return {
            fragmentId: fragment._id,
            text: textSlice,
            type: index ? `ending${index}` : "stem",
            vocabulary: vocabulary,
            isSelectedFragment: fragment._id === selectedFragment,
          };
        });

        const isSelectedWord = wordInstanceId === selectedWord;

        return (
          <WordInstance
            key={wordInstanceId}
            wordInstanceId={wordInstanceId}
            translation={translation}
            leadingPunctuation={leadingPunctuation}
            trailingPunctuation={trailingPunctuation}
            isSelectedWord={isSelectedWord}
            slices={slices}
            modernEquivalent={word.modernEquivalent}
            olLeftOffset={olLeftOffset}
            olRightOffset={olRightOffset}
            indent={beginsParagraph && index === 0}
          />
        );
      })}
      <li className={styles.spacer}></li>
    </ol>
  );

  return (
    <div ref={targetSentenceRef} className={styles.gridTarget}>
      <div className={styles.sentenceContainer}>{content}</div>
    </div>
  );
};

const memoizedTarget = memo(Target);

export default memoizedTarget;
