import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styles from "./FlipDigit.module.css";

const FlipDigit = ({ digit, animate, isLeadingZero, number, isRightVal }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [digitWidth, setDigitWidth] = useState(0);
  const measureRef = useRef(null);
  const prevDigitRef = useRef(digit);
  const prevIsLeadingZeroRef = useRef(isLeadingZero);
  const prevNumberRef = useRef(number);
  const [delayedDigit, setDelayedDigit] = useState(digit);

  useEffect(() => {
    if (isLeadingZero !== prevIsLeadingZeroRef.current) {
      const animationDuration = 1000; // 1 second animation
      const animationTimer = setTimeout(() => {
        prevIsLeadingZeroRef.current = isLeadingZero;
      }, animationDuration);
    }
  }, [isLeadingZero]);

  useEffect(() => {
    if (digit !== prevDigitRef.current) {
      setIsAnimating(true);

      const animationDuration = 1000; // 1 second animation
      const animationTimer = setTimeout(() => {
        prevDigitRef.current = digit;
        prevNumberRef.current = number;
        setIsAnimating(false);
      }, animationDuration);

      return () => {
        clearTimeout(animationTimer);
      };
    }
  }, [digit, number]);

  useLayoutEffect(() => {
    if (measureRef.current) {
      const width = measureRef.current.offsetWidth;
      setDigitWidth(width);
    }
  }, [digit]);

  const direction = animate === "up" ? "Down" : "Up";

  const prevDigit = prevDigitRef.current;
  const prevIsLeadingZero = prevIsLeadingZeroRef.current;
  const prevNumber = prevNumberRef.current;

  const isNineTen = prevNumber === 9 && number === 10;
  const isTenNine = prevNumber === 10 && number === 9;

  const digitStyle = {
    width: isLeadingZero && !isRightVal ? "0px" : `${digitWidth}px`,
    // width: `${digitWidth}px`,
    display: "inline-block",
    transition:
      // isTenNine ? "width 1s ease-out 1s" :
      "width 1s ease-out",
  };

  useEffect(() => {
    if (digit !== prevDigit) {
      const delayTime = 10; // Start updating delayedDigit just after the delay
      const timer = setTimeout(() => {
        setDelayedDigit(digit);
      }, delayTime); // Slightly after the animation starts
      return () => clearTimeout(timer);
    }
  }, [digit, prevDigit, isNineTen]);

  return (
    <div className={styles.digit} style={digitStyle}>
      <div className={styles.digitWrapper}>
        {/* Enter animation for new digit */}
        <div
          className={`${styles.digitElement} ${
            isAnimating ? styles[`flip${direction}Enter`] : ""
          }`}
        >
          <span
            className={styles.currentDigit}
            style={{
              // color: "green",
              opacity: isLeadingZero && !isRightVal ? 0 : 1,
            }}
          >
            {delayedDigit}
          </span>
          <span style={{ position: "absolute", opacity: 0 }} ref={measureRef}>
            {digit}
          </span>
        </div>
        {/* Exit animation for previous digit */}
        <div
          className={`${styles.digitElement} ${
            isAnimating ? styles[`flip${direction}Exit`] : ""
          }`}
        >
          <span
            className={styles.prevDigit}
            style={{
              // color: "red",
              opacity: prevIsLeadingZero && !isRightVal ? 0 : 1,
            }}
          >
            {prevDigit}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FlipDigit;
