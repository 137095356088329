import React, { createContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetUserProfileQuery,
  useUpdateUserKnownFragmentCountMutation,
  useUpdateLastReadMutation,
} from "../../profile/profileApiSlice";
import {
  useGetUserVocabularyQuery,
  useUpsertUserVocabularyMutation,
} from "../../profile/vocabularyApiSlice";
import { useGetBookByIdQuery } from "../../library/booksApiSlice";
import { useGetAllSentencesInBookQuery } from "../sentencesApiSlice";
import { selectWordInstancesBySentenceId } from "../wordInstancesApiSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { triggerAnimation } from "../../../utils/triggerAnimation";
import { selectSentenceById } from "../sentencesApiSlice";
import scrollToTop from "../../../utils/scrollToTop";

export const SentenceContext = createContext();

export const SentenceProvider = ({ children }) => {
  const { bookId, chapter } = useParams();

  // state
  const [currentSentenceId, setCurrentSentenceId] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [translationVisible, setTranslationVisible] = useState({});
  const [showTranslationSection, setShowTranslationSection] = useState(false);
  const [showGrammarSection, setShowGrammarSection] = useState(true);
  const [knownCount, setKnownCount] = useState(null);

  // redux
  // get book
  const {
    data: book,
    // isLoading: book_isLoading,
    // isError: book_isError,
    // error: book_error,
  } = useGetBookByIdQuery(bookId);

  // get sentences
  const {
    data: sentences,
    isSuccess: sentences_isSuccess,
    // isLoading: sentences_isLoading,
    // isError: sentences_isError,
    // error: sentences_error,
  } = useGetAllSentencesInBookQuery(book ? { bookId, chapter } : skipToken);

  // todo
  // get word instances
  // const {
  //   data: wordInstances,
  //   isLoading: wordInstances_isLoading,
  //   isSuccess: wordInstances_isSuccess,
  //   isError: wordInstances_isError,
  //   error: wordInstances_error,
  // } = useGetAllWordInstancesInBookQuery(book ? { bookId, chapter } : skipToken);

  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const [upsertUserVocabulary] = useUpsertUserVocabularyMutation();

  const [updateUserKnownFragmentCount] =
    useUpdateUserKnownFragmentCountMutation();

  const [updateLastRead] = useUpdateLastReadMutation();

  const { data: vocabularies } = useGetUserVocabularyQuery("userVocabulary");

  const currentSentence = useSelector((state) =>
    selectSentenceById(state, bookId, chapter, currentSentenceId)
  );

  const currentWordInstances = useSelector((state) =>
    selectWordInstancesBySentenceId(state, bookId, chapter, currentSentenceId)
  );

  const [selectedWordInstance, setSelectedWordInstance] = useState(null);
  useEffect(() => {
    setSelectedWordInstance(
      currentWordInstances.find((wi) => wi._id === selectedWord)
    );
  }, [currentWordInstances, selectedWord]);

  // side effects
  // scroll to top of target when navigating sentences
  const targetSentenceRef = useRef(null);
  useEffect(() => {
    scrollToTop(targetSentenceRef);
  }, [currentSentenceId]);

  // initialize known fragment count
  useEffect(() => {
    if (book?.language && knownCount === null) {
      (async () => {
        try {
          const data = await updateUserKnownFragmentCount({
            languageId: book?.language,
          }).unwrap();
          setKnownCount(data.knownCount);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [book?.language, knownCount, updateUserKnownFragmentCount]);

  // open book to last read sentence
  useEffect(() => {
    if (
      !currentSentenceId &&
      sentences_isSuccess &&
      sentences?.ids?.length &&
      userProfile
    ) {
      if (userProfile.lastRead && userProfile.lastRead[bookId]?.[chapter]) {
        setCurrentSentenceId(userProfile.lastRead[bookId][chapter]);
      } else {
        setCurrentSentenceId(sentences.ids[0]);
      }
    }
  }, [
    sentences,
    sentences_isSuccess,
    currentSentenceId,
    userProfile,
    bookId,
    chapter,
  ]);

  // save lastRead
  const handleSaveLastRead = async (id) => {
    try {
      await updateLastRead({
        bookId: bookId,
        chapter,
        sentenceId: id,
      }).unwrap();
    } catch (err) {
      console.error("error updating last read:", err);
    }
  };

  // helpers
  const getVocabularyByFragmentId = (fragmentId) => {
    if (vocabularies) {
      return Object.values(vocabularies.entities).find(
        (vocab) => vocab.fragment === fragmentId
      );
    }
  };

  // Sentence.js commented out mark
  const currentSentenceIndex = sentences?.ids.indexOf(currentSentenceId);

  const totalSentences = sentences?.ids.length;

  const onNextSentenceClick = async () => {
    if (
      currentSentenceIndex !== undefined &&
      currentSentenceIndex >= 0 &&
      currentSentenceIndex < sentences.ids.length - 1
    ) {
      triggerAnimation("target", "fadeIn-animation");
      const nextSentenceId = sentences.ids[currentSentenceIndex + 1];
      setCurrentSentenceId(nextSentenceId);
      resetSelections();
      setShowTranslationSection(false);
      await handleSaveLastRead(nextSentenceId);
    }
  };

  const onPrevSentenceClick = async () => {
    if (currentSentenceIndex !== undefined && currentSentenceIndex > 0) {
      triggerAnimation("target", "fadeIn-animation");
      const prevSentenceId = sentences.ids[currentSentenceIndex - 1];
      setCurrentSentenceId(prevSentenceId);
      resetSelections();
      await handleSaveLastRead(prevSentenceId);
    }
  };

  const showTranslation = (wordInstanceId) => {
    setTranslationVisible((prev) => ({
      ...prev,
      [wordInstanceId]: true,
    }));
  };

  // mutation
  const toggleMarkAsKnown = async () => {
    if (selectedFragment) {
      const vocabulary = getVocabularyByFragmentId(selectedFragment);
      switch (vocabulary.state) {
        case "known":
          setKnownCount(knownCount - 1);
          break;
        default:
          setKnownCount(knownCount + 1);
          break;
      }
      try {
        const newState = vocabulary?.state === "known" ? "seen" : "known";

        await upsertUserVocabulary({
          vocabularyId: vocabulary?._id,
          fragmentId: selectedFragment,
          newState,
          languageId: book.language,
        }).unwrap();
        localStorage.setItem("vocabularyMutationFulfilled", "true");
      } catch (err) {
        console.error("error updating vocabulary:", err);
      }
    }
  };

  // mutation
  const markAsSeen = async (wordId, fragmentId, vocabulary) => {
    if (!vocabulary?._id || vocabulary?.state === "new") {
      try {
        await upsertUserVocabulary({
          vocabularyId: vocabulary?._id,
          fragmentId: fragmentId,
          newState: "seen",
          languageId: book.language,
        }).unwrap();
      } catch (err) {
        console.error("Error updating vocabulary:", err);
      }
    }
  };

  const handleFragmentSelection = (wordId, fragmentId, vocabulary) => {
    setSelectedFragment(fragmentId);
    setSelectedWord(wordId);
    showTranslation(wordId);
    markAsSeen(wordId, fragmentId, vocabulary);
  };

  const onNextFragmentClick = (currentWordInstances) => {
    let vocabulary;

    // check no selection
    if (!selectedWord || !selectedFragment) {
      vocabulary = getVocabularyByFragmentId(
        currentWordInstances[0]?.stem?._id
      );
      // select first word stem
      handleFragmentSelection(
        currentWordInstances[0]?._id,
        currentWordInstances[0]?.stem?._id,
        vocabulary
      );
      return;
    }

    // check selection
    const currentWord = currentWordInstances.find(
      (wi) => wi._id === selectedWord
    );
    if (!currentWord) return;

    // get word index
    const selectedWordIdx = currentWordInstances.findIndex(
      (wi) => wi._id === selectedWord
    );

    // get fragment index
    const fragments = ["stem", "ending1", "ending2", "ending3"]
      .map((fragment) => currentWord[fragment]?._id)
      .filter((id) => id);
    const selectedFragmentIdx = fragments.indexOf(selectedFragment);

    // check if additional fragments
    if (
      selectedFragmentIdx >= 0 &&
      selectedFragmentIdx < fragments.length - 1
    ) {
      vocabulary = getVocabularyByFragmentId(
        fragments[selectedFragmentIdx + 1]
      );
      // select next fragment
      handleFragmentSelection(
        selectedWord,
        fragments[selectedFragmentIdx + 1], // next fragment
        vocabulary
      );
      return;
    }

    // check if additional words
    else if (selectedWordIdx < currentWordInstances.length - 1) {
      vocabulary = getVocabularyByFragmentId(
        currentWordInstances[selectedWordIdx + 1].stem._id
      );
      // select next word stem
      handleFragmentSelection(
        currentWordInstances[selectedWordIdx + 1]._id, // next word
        currentWordInstances[selectedWordIdx + 1].stem._id, // stem
        vocabulary
      );
    }

    // if last word call onNextSentenceClick
    else {
      onNextSentenceClick();
    }
  };

  const onPrevFragmentClick = (currentWordInstances) => {
    let vocabulary;

    // check no selection
    if (!selectedWord || !selectedFragment) {
      // check prev sentence
      if (currentSentenceIndex > 0) {
        // go to prev sentence
        onPrevSentenceClick();
      }
      return;
    }

    // check selection
    const currentWord = currentWordInstances.find(
      (wi) => wi._id === selectedWord
    );
    if (!currentWord) return;

    // get word index
    const selectedWordIdx = currentWordInstances.findIndex(
      (wi) => wi._id === selectedWord
    );

    // get fragment index
    const fragments = ["stem", "ending1", "ending2", "ending3"]
      .map((fragment) => currentWord[fragment]?._id)
      .filter((id) => id);
    const selectedFragmentIdx = fragments.indexOf(selectedFragment);

    // check if prev fragments
    if (selectedFragmentIdx > 0) {
      vocabulary = getVocabularyByFragmentId(
        fragments[selectedFragmentIdx - 1]
      );
      // select prev fragment
      handleFragmentSelection(
        selectedWord,
        fragments[selectedFragmentIdx - 1], // prev fragment
        vocabulary
      );
      return;
    }

    // check if prev words
    else if (selectedWordIdx > 0) {
      // get prev word
      const prevWord = currentWordInstances[selectedWordIdx - 1];
      // get prev word fragments
      const prevWordFragments = ["stem", "ending1", "ending2", "ending3"]
        .map((fragment) => prevWord[fragment]?._id)
        .filter((id) => id);
      // get prev word last ending
      const prevWordLastFragment =
        prevWordFragments[prevWordFragments.length - 1];

      vocabulary = getVocabularyByFragmentId(prevWordLastFragment);
      // select prev word last ending
      handleFragmentSelection(
        currentWordInstances[selectedWordIdx - 1]._id, // prev word
        prevWordLastFragment,
        vocabulary
      );
    }

    // if first word call onPrevSentenceClick
    else {
      onPrevSentenceClick();
    }
  };

  const toggleTranslationVisibility = () => {
    setTranslationVisible((prev) => ({
      ...prev,
      [selectedWord]: !prev[selectedWord],
    }));
  };

  const handleSeeTranslationClick = () => {
    toggleTranslationVisibility();
  };

  const resetSelections = () => {
    setSelectedWord(null);
    setSelectedFragment(null);
  };

  return (
    <SentenceContext.Provider
      value={{
        bookId,
        chapter,

        currentSentence,
        currentWordInstances,

        userProfile,

        bookTitle: book.title,
        knownCount,

        targetSentenceRef,

        currentSentenceId,
        currentSentenceIndex,
        beginsParagraph:
          sentences?.entities[currentSentenceId]?.beginsParagraph || false,
        totalSentences,

        selectedWord,
        selectedWordInstance,
        selectedFragment,
        translationVisible,

        showTranslationSection,
        setShowTranslationSection,
        showGrammarSection,
        setShowGrammarSection,

        getVocabularyByFragmentId,
        handleFragmentSelection,
        onNextFragmentClick,
        onPrevFragmentClick,
        onNextSentenceClick,
        onPrevSentenceClick,
        handleSeeTranslationClick,
        toggleMarkAsKnown,
      }}
    >
      {children}
    </SentenceContext.Provider>
  );
};
