import React, { useContext } from "react";
import { ATTRIBUTE_LIST } from "./attributeList";
import MorphologyItem from "./MorphologyItem/MorphologyItem";

import { SentenceContext } from "../../SentenceContext/SentenceContext";

import styles from "./MorphologyContent.module.css";

const MorphologyContent = () =>
  // {
  // selectedWordInstance,
  // getVocabularyByFragmentId,
  // selectedFragment,
  // }
  {
    // todo: use context
    const {
      // targetSentenceRef,
      currentWordInstances,
      // beginsParagraph,
      selectedWord,
      selectedWordInstance,
      selectedFragment,
      // translationVisible,
      // handleFragmentSelection,
      getVocabularyByFragmentId,
      // userProfile,
      // knownCount,
      // bookTitle,
      // currentSentence,
      // totalSentences,
      // currentSentenceId,
      // currentSentenceIndex,
      // onNextSentenceClick,
      // onPrevSentenceClick,
      // showTranslationSection,
      // setShowTranslationSection,
      // showGrammarSection,
      // setShowGrammarSection,
    } = useContext(SentenceContext);

    let content;

    // todo: move into context as state
    // const selectedWordInstance = currentWordInstances.find(
    //   (wi) => wi._id === selectedWord
    // );

    if (selectedWordInstance) {
      const attributes = ATTRIBUTE_LIST(
        selectedWordInstance,
        getVocabularyByFragmentId,
        selectedFragment
      );

      content = attributes?.map((obj) => {
        return (
          <MorphologyItem
            key={obj.attributeName}
            attributeName={obj.attributeName}
            attributeColor={obj.attributeColor}
            attributeValue={obj.attributeValue}
            attributeNameDescription={obj.attributeNameDescription}
            attributeValueDescription={obj.attributeValueDescription}
            selected={obj.selected}
          />
        );
      });
    }

    return <div className={styles.container}>{content}</div>;
  };

export default MorphologyContent;
