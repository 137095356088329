import React, { useState, useEffect, useRef } from "react";
import { SentenceProvider } from "./SentenceContext/SentenceContext";

import { useParams, Link } from "react-router-dom";
import {
  useGetUserProfileQuery,
  useUpdateUserKnownFragmentCountMutation,
} from "../profile/profileApiSlice";
import "../profile/vocabularyApiSlice";
import { useGetBookByIdQuery } from "../library/booksApiSlice";
import { useGetAllSentencesInBookQuery } from "../sentence/sentencesApiSlice";
import { useGetAllWordInstancesInBookQuery } from "./wordInstancesApiSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import ErrMsg from "../../components/shared/ErrMsg/ErrMsg";
import Nav from "./Nav/Nav";
import Target from "./Target/Target";
import Info from "./Info/Info";
import TourPopup from "./TourPopup/TourPopup";
import { TourProvider } from "./Tour/TourProvider";
import Tour from "./Tour/Tour";
import useTitle from "../../hooks/useTitle";
import scrollToTop from "../../utils/scrollToTop";
import CEFR from "./CEFR/CEFR";

import styles from "./Sentence.module.css";
import Toolbar from "./Toolbar/Toolbar";

const Sentence = ({
  showingSSVSettings,
  showingSSVHelp,
  setShowingSSVSettings,
  setShowingSSVHelp,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
  showingTourPopup,
  setShowingTourPopup,
  tourActive,
  setTourActive,
  setCurrentTourStep,
}) => {
  useEffect(() => {
    function setDynamicHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(
        "--dynamic-height",
        `${vh * 100}px`
      );
    }

    function handleViewportChange() {
      const vh = window.visualViewport
        ? window.visualViewport.height * 0.01
        : window.innerHeight * 0.01;
      document.documentElement.style.setProperty(
        "--dynamic-height",
        `${vh * 100}px`
      );
    }

    setDynamicHeight();
    window.addEventListener("resize", setDynamicHeight);
    window.addEventListener("scroll", setDynamicHeight);
    window.visualViewport &&
      window.visualViewport.addEventListener("resize", handleViewportChange);

    return () => {
      window.removeEventListener("resize", setDynamicHeight);
      window.removeEventListener("scroll", setDynamicHeight);
      window.visualViewport &&
        window.visualViewport.removeEventListener(
          "resize",
          handleViewportChange
        );
    };
  }, []);

  useTitle("Sentence View");

  const { bookId, chapter } = useParams();

  const [currentSentenceId, setCurrentSentenceId] = useState(null);
  const [knownCount, setKnownCount] = useState(null);

  // todo: move to context
  // scroll to top of target when navigating sentences
  const targetSentenceRef = useRef(null);
  useEffect(() => {
    scrollToTop(targetSentenceRef);
  }, [currentSentenceId]);

  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const [updateUserKnownFragmentCount] =
    useUpdateUserKnownFragmentCountMutation();

  const {
    data: book,
    isLoading: book_isLoading,
    isError: book_isError,
    error: book_error,
  } = useGetBookByIdQuery(bookId);

  // initialize known fragment count
  useEffect(() => {
    if (book?.language && knownCount === null) {
      (async () => {
        try {
          const data = await updateUserKnownFragmentCount({
            languageId: book?.language,
          }).unwrap();
          setKnownCount(data.knownCount);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [book?.language, knownCount, updateUserKnownFragmentCount]);

  // get sentences
  const {
    data: sentences,
    isLoading: sentences_isLoading,
    isSuccess: sentences_isSuccess,
    isError: sentences_isError,
    error: sentences_error,
  } = useGetAllSentencesInBookQuery(book ? { bookId, chapter } : skipToken);

  // open book to last read sentence
  useEffect(() => {
    if (
      !currentSentenceId &&
      sentences_isSuccess &&
      sentences?.ids?.length &&
      userProfile
    ) {
      if (userProfile.lastRead && userProfile.lastRead[bookId]?.[chapter]) {
        setCurrentSentenceId(userProfile.lastRead[bookId][chapter]);
      } else {
        setCurrentSentenceId(sentences.ids[0]);
      }
    }
  }, [
    sentences,
    sentences_isSuccess,
    currentSentenceId,
    userProfile,
    bookId,
    chapter,
  ]);

  // get word instances
  const {
    data: wordInstances,
    isLoading: wordInstances_isLoading,
    isSuccess: wordInstances_isSuccess,
    isError: wordInstances_isError,
    error: wordInstances_error,
  } = useGetAllWordInstancesInBookQuery(book ? { bookId, chapter } : skipToken);

  let content;

  if (
    !tourActive &&
    (book_isLoading || sentences_isLoading || wordInstances_isLoading)
  ) {
    content = (
      <div className="grid-center">
        <LoadMsg msg="loading" size="2x" />
      </div>
    );
  }

  if (book_isError || sentences_isError || wordInstances_isError) {
    content = (
      <div className="grid-center">
        <ErrMsg
          msgList={[
            book_error?.data?.message,
            sentences_error?.data?.message,
            wordInstances_error?.data?.message,
          ]}
          additionalComponent={
            <Link className={styles.errLink} to="/library">
              return to libary
            </Link>
          }
          size="1x"
        />
      </div>
    );
  }

  if (
    book &&
    sentences_isSuccess &&
    currentSentenceId &&
    wordInstances_isSuccess &&
    wordInstances
  ) {
    content = (
      <SentenceProvider>
        <div className={styles.container}>
          {tourActive ? (
            <TourProvider>
              <Tour
                setTourActive={setTourActive}
                setShowingTourPopup={setShowingTourPopup}
                handleShowSSVSettingsClick={handleShowSSVSettingsClick}
                handleShowSSVHelpClick={handleShowSSVHelpClick}
              />
            </TourProvider>
          ) : (
            <>
              <div className={styles.grid}>
                <Nav
                  showingSSVSettings={showingSSVSettings}
                  showingSSVHelp={showingSSVHelp}
                  setShowingSSVSettings={setShowingSSVSettings}
                  setShowingSSVHelp={setShowingSSVHelp}
                  handleShowSSVSettingsClick={handleShowSSVSettingsClick}
                  handleShowSSVHelpClick={handleShowSSVHelpClick}
                />
                <CEFR />
                <Target />
                <Toolbar />
                <Info />
              </div>
              {(!userProfile.hasSeenTutorial || showingTourPopup) && (
                <TourPopup
                  setTourActive={setTourActive}
                  setCurrentTourStep={setCurrentTourStep}
                  setShowingTourPopup={setShowingTourPopup}
                />
              )}
            </>
          )}
        </div>
      </SentenceProvider>
    );
  }

  return <main className="page-wrapper">{content}</main>;
};

export default Sentence;
