import React, { useRef, useEffect, useState } from "react";
import styles from "./Level.module.css";

const Level = ({ level, onChangeStateUpdate }) => {
  const [prevLevel, setPrevLevel] = useState(level);
  const [isChange, setIsChange] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (prevLevel !== level) {
      // Clear any existing timers
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      setIsChange(true);
      // Notify parent of state change
      onChangeStateUpdate?.(true);

      timerRef.current = setTimeout(() => {
        setPrevLevel(level);

        // Use another timer to reset the animation state
        timerRef.current = setTimeout(() => {
          setIsChange(false);
          // Notify parent of state change
          onChangeStateUpdate?.(false);
        }, 1500);
      }, 1500);
    }

    // Cleanup function
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [level, onChangeStateUpdate]);

  return (
    <div className={styles.levelContainer}>
      <div className={`${styles.level} ${isChange ? styles.fadeIn : ""}`}>
        {level}
      </div>
      <div className={`${styles.prevLevel} ${isChange ? styles.fadeOut : ""}`}>
        {prevLevel}
      </div>
    </div>
  );
};

export default Level;
